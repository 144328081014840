import React from "react"
import styled from "styled-components"
import Footer from "../../components/footer"
import { FaWhatsapp } from "react-icons/fa"
import { Helmet } from "react-helmet"

import { Row, Col } from "antd"

export default class BackEnd extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Vaga para Desenvolvedor Back-End</title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Gosta de desenvolver apps em Elixir e trabalhar remotamente? Vem para a deli, e use seu talento para desenvolver aplicações de ponta!"
          />
        </Helmet>
        <Header>
          <Row style={{ height: "100%" }}>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-start",
                paddingLeft: "24px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "Righteous",
                  fontSize: "1.8em",
                  width: "64px",
                  margin: "0px",
                }}
              >
                deli
              </p>
            </Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col
              xs={18}
              sm={18}
              md={18}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: "24px",
              }}
            >
              <FaWhatsapp size="1.78em" />
              <span
                style={{
                  fontFamily: "IBM Plex Sans",
                  color: "#3f6aea",
                  fontSize: "1.2em",
                  marginLeft: "6px",
                }}
              >
                {"(41) 9 9757-4479"}
              </span>
            </Col>
          </Row>
        </Header>
        <TextSection>
          <Center>
            <Valign>
              <TextContainer>
                <h3
                  style={{
                    fontFamily: "IBM Plex Sans",
                    marginBottom: "24px",
                  }}
                >
                  VAGA PARA DESENVOLVEDOR BACK-END REMOTO
                </h3>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                  }}
                >
                  Sobre nós:
                </h4>
                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Utilizamos Elixir + Phoenix para trazer aos nossos clientes
                  rapidez e robustez imbatíveis. Além de nos dar acesso a uma
                  série de bibleotecas em Erlang, a utilização da OTP (Open
                  Telecom Platform) nos permite criar sistemas em tempo real e
                  organizar cozinhas com grande eficiência. Nesse sentido, a{" "}
                  <span style={{ fontFamily: "Righteous", color: "#3f6aea" }}>
                    deli
                  </span>{" "}
                  traz muita inovação aos sistemas de ponto de venda do Brasil.
                </p>

                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Sobre esta vaga:
                </h4>
                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Buscamos profissionais com capacidade de construir e melhorar
                  o back-end de nossas APIs usando os padrões de paradigma
                  funcional consagrados pela comunidade. O trabalho sera
                  desenvolvido de maneira remota e fornecemos um bônus para
                  ajudar a criar um escritório agradável.
                </p>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Responsabilidades:
                </h4>
                <ul>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Documentar e escrever código com foco em performance e
                    segurança
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Ter autonomia e capacidade de colaborar com uma equipe ágil{" "}
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Escrever testes automatizados
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Apresentar e contribuir nas decisões sobre arquitetura
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Estudar novos frameworks e tecnologias assegurando a
                    viabilidade técnica das ferramentas escolhidas
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Otimizar aplicações usando os behaviors OTP e concorrência
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {" "}
                    Apresentar evoluções para outras equipes e stakeholders
                  </li>
                </ul>

                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Aquilo que procuramos:
                </h4>

                <ul>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    4 ou + anos de experiência desenvolvendo aplicações web
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Conhecimento GIT
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Experiência levantando e mantendo ambientes de produção com
                    Phoenix/Elixir
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Alguma experiência com ciência de dados
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {" "}
                    Um portfolio autêntico que mostra suas habilidades mais
                    fortes
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Experiência com alguma outra linguagem como Javascript ou
                    Python
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Inglês fluente
                  </li>
                </ul>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Tem interesse?
                </h4>

                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Se você se interessou, envie seu currículo e portfolio para
                  <b style={{ color: "#3f6aea" }}> vagas@delipos.com.br</b>
                </p>
              </TextContainer>
            </Valign>
          </Center>
        </TextSection>
        <Footer />
      </div>
    )
  }
}

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  color: #3f6aea;
  height: 48px;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`

const TextSection = styled.div`
  padding-top: 48px;
  width: 100%;
  min-height: 95vh;
  background: white;
`

const TextContainer = styled.div`
  display: inline-block;
  @media (min-width: 992px) {
    width: 600px;
  }

  @media (max-width: 993px) {
    width: 90%;
  }
`

const Center = styled.div`
  display: table;
  width: 100%;
  height: auto;
`
const Valign = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
`
